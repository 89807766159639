const LawyerContent = ({
  picture,
  firmName = '',
  description = '',
  info1 = null,
  info2 = null,
}: {
  picture: string;
  firmName?: string;
  description?: string;
  info1?: string | null;
  info2?: string | null;
}) => (
  <div className="flex gap-4 items-start justify-center mx-auto">
    <div className="">
      <img
        alt="person"
        src={picture || '/images/icons/person.svg'}
        className="rounded-full border-2 border-black w-14"
      />
    </div>
    <div className="text-left flex-grow text-sm">
      {firmName}
      <br />
      <div className="font-thin text-grey-500">{description}</div>
      <div className="flex justify-between items-center mt-4 w-full">
        {info1 && (
          <div className="text-xs text-black bg-gray-100 px-2 py-1 rounded-md inline-block mr-1">
            {info1}
          </div>
        )}
        {info2 && (
          <div className="text-sm text-white bg-darkblue px-2 py-1 rounded-md inline-block">
            {info2}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default LawyerContent;
