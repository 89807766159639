// import {
//   componentTypes,
//   validatorTypes,
// } from '@data-driven-forms/react-form-renderer';
import {
  componentTypes,
  validatorTypes,
  type Schema,
} from '@data-driven-forms/react-form-renderer';
import axios from 'axios';
import dayjs from 'dayjs';
import SimpleForm from '../components/claims/SimpleForm';
import ChooseDate from '../components/claims/meeting/ChooseDate';
import ChooseLawyer from '../components/claims/meeting/ChooseLawyer';
import ChooseSpeciality from '../components/claims/meeting/ChooseSpeciality';
import MeetingList from '../components/claims/meeting/MeetingList';
import SignAndConfirm from '../components/claims/meeting/SignAndConfirm';
import ChoiceButton from '../components/claims/misc/ChoiceButton';
import FileHelper from '../components/structure/elements/FileHelper';
import { API_URL } from '../config/config';
import { ClaimSchema } from '../types/Forms';
import { ClaimData, specialitiesDef } from '../types/claimdata';

const specialityForm: Schema = {
  fields: [
    {
      component: 'choice-list',
      name: 'speciality',
      isRequired: true,
      buttonComponent: ChoiceButton,
      options: [],
      actions: {},
    },
  ],
};

const commentsForm: Schema = {
  fields: [
    {
      name: 'telephone',
      label: 'Votre numéro de téléphone',
      component: componentTypes.TEXT_FIELD,
      isRequired: true,
      helperText:
        "Ceci permettra à l'avocat de vous contacter en cas de problème technique.",
      actions: {
        label: ['translate', 'Votre numéro de téléphone'],
        helperText: [
          'translate',
          "Ceci permettra à l'avocat de vous contacter en cas de problème technique.",
        ],
        validate: [
          'translateErrors',
          [
            {
              type: validatorTypes.REQUIRED,
              message: 'Champ obligatoire',
            },
            {
              type: validatorTypes.PATTERN,
              // eslint-disable-next-line no-useless-escape
              pattern:
                /^(?:0[1-9](?:\s?\d{2}){4}|(?:\+|00)[1-9](?:\s?\d){1,14})$/,
              message: 'Mauvais format',
            },
          ],
        ],
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'Champ obligatoire',
        },
        {
          type: validatorTypes.PATTERN,
          // eslint-disable-next-line no-useless-escape
          pattern: /^(?:0[1-9](?:\s?\d{2}){4}|(?:\+|00)[1-9](?:\s?\d){1,14})$/,
          message: 'Mauvais format',
        },
      ],
    },
    {
      name: 'clientComment',

      component: componentTypes.TEXTAREA,
      maxLength: 500,
      isRequired: true,
      inputClass:
        'mt-1 mb-6 block w-full border-grey-100 border h-80 rounded-md',
      actions: {
        label: ['translate', 'Votre problématique'],
        validate: [
          'translateErrors',
          [
            {
              type: validatorTypes.REQUIRED,
              message: 'Champ obligatoire',
            },
          ],
        ],
      },
    },
    {
      component: componentTypes.FIELD_ARRAY,
      name: 'documents',
      fieldKey: 'field_array',
      initializeOnMount: true,

      actions: {
        title: ['translate', 'Ajouter des documents'],
        addLabel: ['translate', '+ Ajouter un document'],
        removeLabel: ['translate', 'supprimer'],
      },
      description: '5 documents max.',
      minItems: 0,
      maxItems: 5,
      validate: [
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 5,
          message: ((value) =>
            `pas plus de ${value} document(s).`) as unknown as string, // TODO tell data driven forms this is a mistake
        },
      ],
      fields: [
        {
          component: 'file-upload',
          isRequired: true,
          btnClass: 'btn m-1 text-sm !bg-black',
          labelClass: 'h-5 block',
          className: 'file-upload',
          helperText: <FileHelper />,

          actions: {
            btnLabel: ['translate', 'Choisir un fichier'],
            noFileLabel: ['translate', 'Aucun fichier choisi'],
            validate: [
              'translateErrors',
              [
                { type: 'file-required', message: 'Champ obligatoire' },
                {
                  type: 'docfile',
                  message:
                    'Les formats acceptés sont : png, jpg, jpeg, pdf, doc, docx',
                },
              ],
            ],
          },
        },
      ],
    },
  ],
};

const schemaVisio: ClaimSchema = [
  {
    name: 'meeting_nextmeeting',
    component: MeetingList,
    completion: 100,
    data: {
      title: 'Consultation juridique',
      intro: 'list-intro',
    },
  },
  {
    name: 'meeting_speciality',
    component: ChooseSpeciality,
    completion: 20,
    data: {
      title: 'Consultation juridique',
      intro: 'speciality-intro',
      formSchema: specialityForm,
      autoSubmit: true,
      next: 'meeting_choosedate',
    },
  },

  {
    name: 'meeting_choosedate',
    component: ChooseDate,
    completion: 30,
    data: {
      title: 'Consultation juridique',
      intro: 'choosedate-intro',
      prev: 'meeting_speciality',
      next: (data) => {
        if (data.lawyers.length > 1) return 'meeting_chooselawyer';
        return 'meeting_notes';
      },
    },
  },

  {
    name: 'meeting_chooselawyer',
    component: ChooseLawyer,
    completion: 50,
    data: {
      title: 'Consultation juridique',
      intro: 'lawyers-intro',
      prev: 'meeting_choosedate',
      next: 'meeting_notes',
    },
  },
  {
    name: 'meeting_notes',
    component: SimpleForm,
    completion: 20,
    data: {
      title: 'Consultation juridique',
      intro: 'comments-intro',
      fileUploadPath: 'rendezVousJuridique',
      formSchema: commentsForm,
      prev: 'meeting_choosedate',
      next: 'meeting_signandconfirm',
    },
  },
  {
    name: 'meeting_signandconfirm',
    component: SignAndConfirm,
    completion: 70,
    submit: {
      variables: [
        'lawyer',
        'date',
        'duration',
        'documents',
        'clientComment',
        'speciality',
        'telephone',
        'timezone',
      ],
      path: '/claims/rendezVousJuridique',
    },
    data: {
      title: 'Consultation juridique',
      intro: 'confirm-intro',
      prev: 'meeting_choosedate',
      next: 'meeting_nextmeeting',
    },
  },
];

const initializeData = async (userData): Promise<ClaimData | object> => {
  if (!userData.services?.rendezVousJuridique) return {};
  const specDef: specialitiesDef = await axios
    .get(`${API_URL}/lawyers/specialities`)
    .then((res) => res.data);
  const { claim: appointment } = userData.services?.rendezVousJuridique
    .appointment
    ? await axios
        .get(
          `${API_URL}/claims/${userData.services.rendezVousJuridique.appointment}`
        )
        .then((res) => res.data)
    : { claim: null };
  const userConf = userData.services?.rendezVousJuridique;

  // timezone
  const localOffset = dayjs().local().format('ZZ');
  const appOffset = dayjs().tz().format('ZZ');

  return {
    telephone: userData.telephone,
    userId: userData.number,
    specialitiesDefinitions: specDef,
    specialities: userConf.specialities,
    appointment,
    appointmentId: userConf.appointment,
    duration: userConf.types[0].duration,
    prebook: userConf.prebook,
    insurer: userConf.insurer,
    generateCH: userConf.generateCH,
    timezone: localOffset !== appOffset ? dayjs.tz.guess() : undefined,
  };
};

const exported = {
  title: '',
  schema: schemaVisio,
  initializeData,
};
export default exported;
